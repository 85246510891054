import type { IUiSnackbarHookParams } from '@experiences/interfaces';
import { UiSnackbarContext } from '@experiences/util';
import type { AlertColor } from '@mui/material/Alert';
import Alert from '@mui/material/Alert';
import Fade from '@mui/material/Fade';
import Snackbar from '@mui/material/Snackbar';
import type { PropsWithChildren } from 'react';
import React from 'react';

const NOTIFICATION_DURATION = 3000 ;
const TRANSITION_DURATION = 500 ;

export const SnackbarProvider = ({ children }: PropsWithChildren) => {
    const [ open, setOpen ] = React.useState<boolean>(false);
    const [ snackbarMessage, setMessage ] = React.useState<React.ReactNode | null>(null);
    const [ snackbarParams, setSnackbarParams ] = React.useState<IUiSnackbarHookParams>({
        variant: 'success',
        anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
        },
        TransitionComponent: Fade,
        autoHideDuration: NOTIFICATION_DURATION,
    });

    const enqueueSnackbar = React.useCallback((message: React.ReactNode, parameters?: IUiSnackbarHookParams) => {
        setOpen(true);
        setMessage(message);
        if (parameters) {
            setSnackbarParams((prevState) => ({
                ...prevState,
                ...parameters,
            }));
        }
    }, [ setOpen, setMessage, setSnackbarParams ]);

    const handleClose = (_event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    const NotistackToMuiAlertMapping = {
        ['default']: 'info',
        ['info']: 'info',
        ['success']: 'success',
        ['error']: 'error',
        ['warning']: 'warning',
    };

    return (
        <>
            <UiSnackbarContext.Provider value={{ enqueueSnackbar }}>
                {children}
            </UiSnackbarContext.Provider>
            <Snackbar
                data-testid="custom-snackbar"
                open={open}
                autoHideDuration={snackbarParams?.autoHideDuration}
                transitionDuration={TRANSITION_DURATION}
                onClose={handleClose}
                anchorOrigin={snackbarParams?.anchorOrigin}
                TransitionComponent={snackbarParams?.TransitionComponent}
            >
                <Alert
                    data-testid="custom-alert"
                    onClose={handleClose}
                    severity={NotistackToMuiAlertMapping[snackbarParams?.variant ?? 'default'] as AlertColor}
                    variant='standard'
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </>
    );
};
