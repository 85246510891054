import Divider from '@mui/material/Divider';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx';
import React from 'react';
import { useIntl } from 'react-intl';

import { UiText } from '../UiText/UiText';

const useStyles = makeStyles(() =>
    createStyles({
        dividerContainer: {
            display: 'flex',
            alignItems: 'center',
        },
        divider: {
            width: '40%',
            background: '#ddd',
        },
        centerText: { width: '20%' },
        or: {
            display: 'flex',
            justifyContent: 'space-around',
        },
    }),
);

interface IUiDividerProps {
    className?: string;
}

export const UiDivider = ({ className }: IUiDividerProps) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();

    return (
        <div className={clsx(classes.dividerContainer, className)}>
            <Divider className={classes.divider} />
            <div className={classes.centerText}>
                <UiText className={classes.or}>
                    {translate({ id: 'OR' })}
                </UiText>
            </div>
            <Divider className={classes.divider} />
        </div>
    );
};
