import Backdrop from '@mui/material/Backdrop';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import { PortalLoader } from '@uipath/portal-shell-react';
import clsx from 'clsx';
import React, { useMemo } from 'react';

const useStyles = makeStyles(theme =>
    createStyles({
        root: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: theme.palette.semantic.colorBackground,
        },
        fullPage: { height: '100vh' },
        backdrop: { zIndex: 999999 },
        container: {
            margin: '20px',
            width: '200px',
            maxWidth: '100%',
        },
    }),
);

export const UiLoader = ({
    size = '72px', type, ...props
}: { size?: string; type?: 'backdrop' | 'full' }) => {
    const classes = useStyles();

    const loadingElement = useMemo(
        () => (
            <div
                {...props}
                className={classes.container}>
                <PortalLoader
                    data-testid="portal-loader"
                    size={size} />
            </div>
        ),
        [ classes.container, props, size ],
    );

    return type === 'backdrop' ? (
        <Backdrop
            data-cy="ui-loader-backdrop"
            data-testid="ui-loader-backdrop"
            open
            className={classes.backdrop}>
            {loadingElement}
        </Backdrop>
    ) : (
        <div
            data-cy="ui-loader"
            data-testid="ui-loader"
            className={clsx(classes.root, type === 'full' ? classes.fullPage : '')}>
            {loadingElement}
        </div>
    );
};
