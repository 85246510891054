import type { Token } from '@experiences/interfaces';

export const hasSurname = (fullName?: string): boolean => {
    if (!fullName) {
        return false;
    }
    return fullName.split(' ').length > 1;
};

export const getFirstNameFromFullName = (fullName?: string, allowEmails = true): string | undefined => {
    if (fullName?.includes('@')) {
        return allowEmails ? fullName : '';
    }
    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
    return (hasSurname(fullName) ? fullName?.split(' ')[0] : fullName) || undefined;
};

export const getSurnameFromFullName = (fullName?: string): string | undefined =>
    hasSurname(fullName) ? fullName?.split(' ').splice(1)
        .join(' ') : '';

export const fixName = (name: string): string => name.replace(/\s\s+/g, ' ').trim();

export const validateUsername = (username: string): boolean => !!username && !!username.trim();

export const getFullName = (firstName?: string, lastName?: string) => {
    if (!firstName && !lastName) {
        return '';
    }
    if (!firstName && lastName) {
        return lastName;
    }
    if (!lastName && firstName) {
        return firstName;
    }
    return `${firstName} ${lastName}`;
};

export const getFullNameDuplicated = (fullName: string) => {
    const splitName = fullName?.split(' ');
    return splitName?.length > 2 ? `${splitName[0]} ${splitName[1]}` : fullName;
};

export const getFirstNameFromToken = (claims: Token | undefined, allowEmails?: boolean): string => (
    claims?.given_name ??
    claims?.['https://cloudrpa/userinfo']?.firstName ??
    getFirstNameFromFullName(claims?.name, allowEmails) ??
    ''
);

export const getLastNameFromToken = (claims: Token | undefined): string => (
    claims?.family_name ??
    claims?.['https://cloudrpa/userinfo']?.lastName ??
    getSurnameFromFullName(claims?.name) ??
    ''
);

export const getDisplayName = ({
    displayName, name, surname,
}: { displayName?: string; name?: string; surname?: string }): string => {
    if (displayName) {
        return displayName;
    }
    return getFullName(name, surname);
};
