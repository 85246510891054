import type { IUiDialogHookParams } from '@experiences/interfaces';
import {
    dialogCoordinator,
    DialogHandler,
    UiDialogContext,
} from '@experiences/util';
import DoneIcon from '@mui/icons-material/Done';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';
import Button from '@mui/material/Button';
import type { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React, {
    useEffect,
    useMemo,
} from 'react';
import { flushSync } from 'react-dom';
import { useIntl } from 'react-intl';

import { UiDialog } from './UiDialog';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        bodyText: {
            minWidth: '300px',
            maxWidth: '700px',
        },
        redIcon: {
            color: theme.palette.semantic.colorErrorIcon,
            fontSize: '32px',
        },
        greenIcon: {
            color: theme.palette.semantic.colorSuccessIcon,
            fontSize: '32px',
        },
        yellowIcon: {
            color: theme.palette.semantic.colorWarningIcon,
            fontSize: '32px',
        },
        blueIcon: {
            color: theme.palette.semantic.colorPrimary,
            fontSize: '32px',
        },
        primaryButton: {
            backgroundColor: theme.palette.semantic.colorErrorIcon,
            '&:hover': { backgroundColor: `${theme.palette.semantic.colorErrorText} !important` },
        },
    }),
);

export const UiDialogProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();

    const resolve = React.useRef<((proceed: boolean) => void) | undefined>(undefined);
    const [ dialogHookParams, setDialogHookParams ] = React.useState<IUiDialogHookParams>({});

    const dialogHandler = useMemo(
        () =>
            new DialogHandler('useShowDialog', (dialogParams: IUiDialogHookParams) => {
                setDialogHookParams(dialogParams);
            }),
        [ setDialogHookParams ],
    );

    useEffect(() => {
        dialogCoordinator.addDialogHandler(dialogHandler);

        return () => dialogCoordinator.removeDialogHandler(dialogHandler.name);
    }, [ dialogHandler ]);

    const createDialog = React.useCallback((parameters: IUiDialogHookParams) => {
        const pr: Promise<boolean> = new Promise(function(res) {
            if (res) {
                resolve.current = res;
                // We must use flushSync here because we need to update the state for the dialog to show
                flushSync(() => {
                    setDialogHookParams(parameters);
                });
            }
        });
        return pr;
    }, []);

    const handleClose = React.useCallback(
        (proceed = false) => {
            if (resolve.current) {
                resolve.current(proceed);
            }
            resolve.current = undefined;
            // We must use flushSync here because we need to update the state for the dialog to hide
            flushSync(() => {
                setDialogHookParams({});
            });
        },
        [ resolve ],
    );

    const handleActions = React.useMemo(
        () =>
            !dialogHookParams.hideActions && !dialogHookParams.customDialogContent
                ? [
                    dialogHookParams.showCancel ? (
                        <Button
                            key="cancelButton"
                            variant="outlined"
                            size="small"
                            color="primary"
                            onClick={() => handleClose()}
                            data-cy="secondary-button-confirmation"
                            data-testid="ui-dialog-cancel-button"
                        >
                            {dialogHookParams.cancelButtonText ?? translate({ id: 'CLIENT_CANCEL' })}
                        </Button>
                    ) : (
                        undefined
                    ),

                    <Button
                        key="primaryButton"
                        variant="contained"
                        size="small"
                        data-cy="primary-button-confirmation"
                        data-testid="ui-dialog-ok-button"
                        onClick={() => handleClose(true)}
                        className={dialogHookParams.icon === 'error' ? classes.primaryButton : undefined}
                    >
                        {dialogHookParams.primaryButtonText ?? 'OK'}
                    </Button>,
                ]
                : undefined,
        [ dialogHookParams, handleClose, translate, classes.primaryButton ],
    );

    return (
        <>
            <UiDialogContext.Provider
                value={{ createDialog }}
            >
                {children}
            </UiDialogContext.Provider>
            {Object.keys(dialogHookParams).length ? (
                <UiDialog
                    dataCy={dialogHookParams.dataCy}
                    title={dialogHookParams.title}
                    width={dialogHookParams.width ?? 'auto'}
                    icon={
                        dialogHookParams.icon ? (
                            typeof dialogHookParams.icon === 'string' ? (
                                dialogHookParams.icon === 'warning' ? (
                                    <WarningIcon
                                        data-testid="ui-dialog-warning-icon"
                                        className={classes.yellowIcon} />
                                ) : dialogHookParams.icon === 'error' ? (
                                    <ErrorIcon
                                        data-testid="ui-dialog-error-icon"
                                        className={classes.redIcon} />
                                ) : dialogHookParams.icon === 'success' ? (
                                    <DoneIcon
                                        data-testid="ui-dialog-done-icon"
                                        className={classes.greenIcon} />
                                ) : dialogHookParams.icon === 'info' ? (
                                    <InfoIcon
                                        data-testid="ui-dialog-info-icon"
                                        className={classes.blueIcon} />
                                ) : (
                                    undefined
                                )
                            ) : (
                                dialogHookParams.icon
                            )
                        ) : (
                            undefined
                        )
                    }
                    close={dialogHookParams.unclosable ? () => {} : () => handleClose()}
                    hideClose={dialogHookParams.unclosable}
                    actions={handleActions}
                    bodyActions={!dialogHookParams.primaryButtonText && !dialogHookParams.showCancel}
                >
                    {dialogHookParams.customDialogContent ? (
                        <dialogHookParams.customDialogContent
                            closeDialog={handleClose}
                            createDialog={createDialog}
                            {...dialogHookParams.customDialogContentProps}
                        />
                    ) : (
                        dialogHookParams.body && <div
                            data-testid="ui-dialog-body"
                            className={classes.bodyText}>
                            {dialogHookParams.body}
                        </div>
                    )}
                </UiDialog>
            ) : null}
        </>
    );
};
