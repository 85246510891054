import { FontVariantToken } from '@uipath/apollo-core';
import { ApTypography } from '@uipath/portal-shell-react';
import type { Components } from '@uipath/portal-shell-types';
import React from 'react';

interface UiTextProps extends React.HTMLAttributes<Components.ApTypography>, Omit<Components.ApTypography, 'theme' | 'variant'> {
    variant?: FontVariantToken;
}

const UiTextComponent: React.FC<UiTextProps> = ({
    children,
    variant = FontVariantToken.fontSizeM,
    ...props
}) => (
    <ApTypography
        variant={variant}
        {...props}>
        {children}
    </ApTypography>
);

UiTextComponent.displayName = 'UiText';
export const UiText = UiTextComponent;
